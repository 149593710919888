// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

// Fix for the confirm modal showing behind the modal who generates it
#confirm-modal {
  z-index: 100;
}

.accountability_without_children {
  &__grid {
    @apply grid md:grid-cols-3 items-start gap-x-10 gap-y-8 md:gap-y-16;

    /* display the titles only for the first row in desktop */
    & > :nth-child(1) &-title {
      @apply md:block;
    }

    &-title {
      @apply block md:hidden mb-8 text-gray-2 uppercase font-semibold;
    }

    .flash {
      @apply m-0;
    }
  }
}

.main-header__language-container > #trigger-dropdown-language-chooser {
  gap: 0.1rem !important;
  flex-direction: column;
  padding: 0.25rem 0.5rem;

  span {
    display: block;
    font-weight: 400;
    color: rgb(var(--secondary-rgb) / 1);
    font-size: 14px;
  }

  svg {
    fill: rgb(var(--secondary-rgb) / 1);
  }
}

.main-footer__language {
  z-index: 1000;
}

.main-header__language-container > #trigger-dropdown-language-chooser:hover {
  background-color: #f3f4f7 !important;
  border-radius: 0.25rem;
  text-decoration: underline;
  text-decoration-color: rgb(var(--secondary-rgb) / 1);
}

#dropdown-menu-language-chooser {
  margin-left: 0 !important;
  margin-right: 0 !important;
  align-items: center !important;
}

#dropdown-menu-language-chooser > ul > li > a {
  font-size: 14px;
  padding: 0.5rem;
}

.mobile_menu__language-container {
  padding-top: 12px;
  padding-bottom: 12px;
}

.brand-bar {
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
}

.bcn-cat-link > a {
  font-weight: 600;
  font-size: 20px;
  color: black;
  line-height: 35px;
  letter-spacing: -0.5px;
  background-size: 0;
  text-decoration: none;
  font-family: "Source Sans Pro";
}

.logo-mobile {
  display: none;
}

.logo-desktop {
  padding-right: 0;
}

.bcn-cat-link > a:hover {
  text-decoration: underline;
}

body > div.layout-container > header > div.brand-bar > div.bcn-logo > a > span {
  display: none;
}

@media screen and (max-width: 1279px) {
  .brand-bar {
    padding-right: 1rem !important;
  }
}

@media screen and (max-width: 1024px) {
  .logo-desktop {
    display: none;
  }

  .logo-mobile {
    display: block;
  }
}

@media (min-width: 768px) {
  .main-header__language-container > #trigger-dropdown-language-chooser {
    display: flex !important;
  }
}

@media (max-width: 1023px) {
  .main-header__language-container > #trigger-dropdown-language-chooser {
    flex-direction: row !important;
  }

  #trigger-dropdown-language-chooser > svg {
    display: none;
  }

  #trigger-dropdown-language-chooser > div > svg {
    display: none;
  }

  #dropdown-menu-main-mobile > div.main-header__language-container {
    display: flex;
    flex-direction: row;
    width: 10rem;
    margin: auto;
    color: rgb(168 44 44) !important;
    font-weight: 600 !important;
  }

  #dropdown-menu-language-chooser > ul > li > a,
  #dropdown-menu-language-chooser > ul > li,
  #trigger-dropdown-language-chooser > div > span,
  #trigger-dropdown-language-chooser {
    padding: 0 !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
  }
}
